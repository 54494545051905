var resizeTimer;
var slick_nav_trigger_width = 1200;
var mobiel_trigger_width    = 768;
var header_default_height   = 86;
var header_small_height     = 73;

$(window).bind("load", function () {
    innitNav();
    // initBody();
    // initNavTiny();
    initObjectsSearch();
    initSlick();
    initEmployees(); 
    initOffices();
    initZoekopdracht();
    initSearch();
    initVideoPopUp(); 
    initBackToTop();
    initHomepage(); 
    initListButton();
    initSmoothScroll();
    initMatchHeight(); 
    initEqualHeights();
    initShowAlert();
});

$(function () {
    initContactMaps();
});

$(window).resize(function () {
    clearTimeout($.data(this, 'resizeTimer'));
    $.data(this, 'resizeTimer', setTimeout(function () {
        innitNav();
        // initBody();  
        initEmployees(); 
        initMatchHeight();
        initEqualHeights();
        //initHomepage();  
    }, 250));
});

$(window).on("scroll touchmove", function () {
  // initNavTiny();
});


// Wel niet tonen van back to top
$(window).scroll(function() {
    if ($(this).scrollTop()) {
        $('#toTop').fadeIn();
    } else {
        $('#toTop').fadeOut();
    }
});

function initBody(){
    if($( window ).width() > slick_nav_trigger_width){
        $('.bodyspacing').css('padding-top', header_default_height);
    }else{
        $('.bodyspacing').css('padding-top', header_small_height);
    }
}

function initShowAlert() {
    if (document.querySelector('#showAlert')) {
        function openCloseLogin() {
            const exitButton = document.querySelector('#popup #exit');
            const closeButton = document.querySelector('#popup #close');

            openWindow();

            if (closeButton) {
                closeButton.addEventListener('click', () => {
                    closeWindow();
                });
            }

            if (exitButton) {
                exitButton.addEventListener('click', () => {
                    closeWindow();
                });
            }
        }

        function openWindow() {
            const popup = document.querySelector('#popup');

            popup.classList.add('open');
        }

        function closeWindow() {
            const popup = document.querySelector('#popup');

            popup.classList.remove('open');
        }

        openCloseLogin();
    }
}

//
// MatchHeight columns
//
var initMatchHeight = function(){
    if( $(window).width < mobiel_trigger_width ){
        $('.matchHeightRow').css('min-height','auto');
    }else{ 
        $('.matchHeightRow').matchHeight({
            byRow: true,
            property: 'min-height',
            target: null,
            remove: false 
        });
    }
}


var initObjectsSearch = function(){
    $( "#searchObjects #searchContainer" ).css('display','block');
    if($( window ).width() <= mobiel_trigger_width){
        $( "#searchObjects #searchContainer" ).css('display','none');
    }
    $('#searchObjects .btn').unbind().on('click',function(){
        $( this ).toggleClass( "active");
        $( "#searchObjects #searchContainer" ).slideToggle( "slow");
    });
}

var initBackToTop = function(){ 
    $("#toTop").click(function (event) {
        event.preventDefault();
        $("html, body").animate({scrollTop: 0}, 1000);
    });
}

function initSlick() {
    if ($('#slider').length) {
        setTimeout(function() {
            $('#slider').hide().css('visibility','visible').fadeIn('slow');
        }, 200);
    }

    // Default carousel
    if ($('.carousel').length > 0) {
        $('.carousel').slick({
            dots: true,
            arrows: false,
            autoplay: true
        });
    }
    // Default page
    if ($('.page-carousel').length > 0) {
        $('.page-carousel').slick({
            dots: true,
            arrows: false,
            autoplay: true
        });
        $('.header-carousel button.slick-prev').html('<i class="fa fa-angle-left" aria-hidden="true"></i>');
        $('.header-carousel button.slick-next').html('<i class="fa fa-angle-right" aria-hidden="true"></i>');
    }

    // Header carousel
    if ($('.header-carousel').length > 0) {
        $('.header-carousel').slick({
            dots: false,
            arrows: true,
            autoplay: true,
            adaptiveHeight: true
        });
        $('.header-carousel button.slick-prev').html('<i class="fa fa-angle-left" aria-hidden="true"></i>');
        $('.header-carousel button.slick-next').html('<i class="fa fa-angle-right" aria-hidden="true"></i>');
    } 
    // Homepage new object carousel
    if ($('.homepage-new-objects').length > 0) {
        $('.homepage-new-objects').slick({
              dots: true,
              arrows: false,
              infinite: false,
              speed: 300,
              slidesToShow: 4,
              slidesToScroll: 4,
              responsive: [
                {
                  breakpoint: 1025,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: mobiel_trigger_width,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                } 
              ]
        });
    }

    if ($('.headertext-normal').length) {
        $('.headertext-normal').each(function() {
            $(this).css('height', $(this).children('h2').outerHeight());

            if ($(this).hasClass('middle-left')) {
                $(this).css('width', $(this).children('h2').outerWidth());
            }
        });
    }
}  

var initVideoPopUp = function(){
    $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,

        fixedContentPos: false
    });
} 

var initHomepage = function(){
    if ($('#homepage').length > 0) {
        $('#homepage #objectvdmaand .object .image').css('height',0);
        if($( window ).width() > mobiel_trigger_width){ 
            $('#homepage #objectvdmaand .object .image').css('height', $('#objectvdmaand .objectvdmaand .object .info').height()+62); // 62 is de padding + border
        }else{
            $('#homepage #objectvdmaand .object .image').css('height',250);
        }

        //Facebook
        if ($('#facebookContainer').length > 0) {
            var facebook_width  = $('#facebookContainer').width();   
            var facebook_height = 500;
            if($( window ).width() > 768) facebook_height = 500; 
            if($( window ).width() > 991) facebook_height = $('#homepage #socialmedia .carousel .slick-list').height();  
            // Set heights
            $('#facebookContainer').css('height',facebook_height);
            $('#youtubeContainer').css('height',facebook_height);
            $('#facebookContainer').html('<div class="fb-page" data-href="https://www.facebook.com/pages/Dupont-ERA-Makelaars/321406691210148" data-width="' + facebook_width + '" data-height="500" data-small-header="false" data-adapt-container-width="true"  data-tabs="timeline" data-small-header="true" data-adapt-container-width="true" data-hide-cover="true" data-show-facepile="false"><div class="fb-xfbml-parse-ignore"><blockquote cite="https://www.facebook.com/facebook"><a href="https://www.facebook.com/facebook">Facebook</a></blockquote></div></div>').fadeIn();
            // Render facebook plugin
            FB.XFBML.parse();    
        }
    }
}

var initOffices = function(){
    if ($('#offices').length > 0) {
         $('#offices .office').each(function(){
            var address = $(this).find('.gmap_locatie').data('address');
            var id = $(this).find('.gmap_locatie').data('id');
            var geocoder = new google.maps.Geocoder();
            var map = new google.maps.Map(document.getElementById("gmap_locatie_"+id), {
                zoom: 15,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                scrollwheel: false
            });

            if (geocoder) {
                geocoder.geocode({
                  'address': address
                }, function(results, status) { 
                    if (status == google.maps.GeocoderStatus.OK) {
                        if (status != google.maps.GeocoderStatus.ZERO_RESULTS) {
                          map.setCenter(results[0].geometry.location);
                          var marker = new google.maps.Marker({
                                position: results[0].geometry.location,
                                map: map,
                                title: address
                          }); 
                        } else {
                          alert("No results found");
                        }
                    } else {
                        alert("Geocode was not successful for the following reason: " + status);
                    }
                });
            }
        });
    }
}

var initEmployees = function(){
    if ($('#employees').length > 0){
        $('#employees .employee').height('auto');
        if($( window ).width() > mobiel_trigger_width) { 
            var highestBox = 0;
                $('#employees .employee').each(function(){  
                        if($(this).height() > highestBox){  
                        highestBox = $(this).height();  
                }
            });     
            $('#employees .employee').height(highestBox);
        }
    }
}

var initZoekopdracht = function(){
    if ($('#zoekopdracht').length > 0) {
        $('#zoekopdracht #prijs_min option').first().text('Minimale prijs');
        $('#zoekopdracht #prijs_max option').first().text('Maximale prijs');
        $('#zoekopdracht #prijs_min2 option').first().text('Minimale prijs');
        $('#zoekopdracht #prijs_max2 option').first().text('Maximale prijs');
        
        $('#zoekopdracht #koophuur').change(function(){
            if($(this).val() === "kopen"){
                $('#zoekopdracht #prijs_min, #zoekopdracht #prijs_max').attr('disabled', false).parents('.form-group').css('display', 'inline-block');
                $('#zoekopdracht #prijs_min2, #zoekopdracht #prijs_max2').attr('disabled', true).parents('.form-group').css('display', 'none');
            }else{ 
                $('#zoekopdracht #prijs_min, #zoekopdracht #prijs_max').attr('disabled', true).parents('.form-group').css('display', 'none');
                $('#zoekopdracht #prijs_min2, #zoekopdracht #prijs_max2').attr('disabled', false).parents('.form-group').css('display', 'inline-block');
            }
        }).change();
    }
};


var initSearch = function(){
    if ($('#search').length > 0 || $('#searchObjects').length > 0) {
        $('#woonhuissoort option').first().text('Type');
        $('#plaats option').first().text('Plaats');
        $('#prijs_min option').first().text('Minimale prijs');
        $('#prijs_max option').first().text('Maximale prijs');
        $('#prijs_min2 option').first().text('Minimale prijs');
        $('#prijs_max2 option').first().text('Maximale prijs');
        
        $('#koophuur').change(function(){
            if($(this).val() == 'koop'){
                $('#prijs_min, #prijs_max').attr('disabled', false).parents('.form-group').css('display', 'inline-block');
                $('#prijs_min2, #prijs_max2').attr('disabled', true).parents('.form-group').css('display', 'none');
            }else{
                $('#prijs_min, #prijs_max').attr('disabled', true).parents('.form-group').css('display', 'none');
                $('#prijs_min2, #prijs_max2').attr('disabled', false).parents('.form-group').css('display', 'inline-block');
            }
        }).change();
    }
};

var innitNav = function(){

    $('#slider').css('margin-top', '144px');

    if ($(window).width() < 767) {
        $('#slider').css('margin-top', '110px');
    }

    if ($(window).width() < 1024){
        $('#menu').slicknav({
            label: '',
            appendTo: '#header',
            easingOpen: "swing",
            easingClose: "swing",
            closedSymbol: '<i class="fa fa-chevron-right" aria-hidden="true"></i>',
            openedSymbol:'<i class="fa fa-chevron-down" aria-hidden="true"></i>',
        });
        $('nav').hide();
        $('.slicknav_menu').show();
    }else{
        $('nav').show();
        $('.slicknav_menu').hide();
    }
} 

var initNavTiny = function(){
    $('#header').toggleClass('tiny', $(document).scrollTop() > $('#header').height());
    $('#header .phone').toggleClass('tiny', $(document).scrollTop() > $('#header').height());
} 

function initContactMaps() {
    if ($('#maps').length) {
        var latlng = new google.maps.LatLng($('#maps').data('lat'), $('#maps').data('lon'));
        
        map = new google.maps.Map(document.getElementById("maps"), {
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            center: latlng,
            scrollwheel: false
        });

        var marker = new google.maps.Marker({
            position: latlng,
            map: map //, icon: '/img/icon-map.png'
        });
    }
}

function initListButton() {
    if ($('.list').length) {
        $('.list ul li').each(function() {
            if ($(this).has('a').length) {
                $(this).addClass('no-list-style');
            }
        });
    }
}

function initSmoothScroll() {
    if (location.hash) {
    setTimeout(function() {

      window.scrollTo(0, 0);
    }, 1);
  }
  var $split = window.location.href.split('#'),
    $anchor;

  if ($split.length > 1) {
    $anchor = $split[1];

    if ($('#' + $anchor).length) {
      $('html, body').animate({
        scrollTop: $( '#' + $anchor ).offset().top  - (header_default_height + 44)
      }, 500);
    }
  }
}

function initEqualHeights() {
    if ($('.equalHeights').length && $(window).width() > 767) {
        $('.equalHeights').each(function() {
            var split = $(this).data('equals').split(','),
                parent = $(this);

            if (split.length > 1) {
                for (var i = 0; i < split.length; i++){
                    var height = 0;

                    parent.find($(split[i])).css('height', 'inherit');

                    parent.find($(split[i])).each(function() {
                        if ($(this).outerHeight() > height) {
                            height = $(this).outerHeight();
                        }
                    });

                    parent.find($(split[i])).css('height', height);
                }
            } else {
                var height = 0;

                $($(this).data('equals')).css('min-height', 0);
                
                $(this).find($(this).data('equals')).each(function() {
                    if ($(this).outerHeight() > height) {
                        height = $(this).outerHeight();
                    }
                });

                $($(this).data('equals')).css('min-height', height);
            }
        });
    }
}